<template>
  <div
    class="avatar lighten-4"
    :class="[colorClass, sizeClass, onlineClass]"
    v-if="avatar"
  >
    <img
      :src="require(`@/assets/images/avatars/${avatar}`)"
      class="avatar-image"
    />
    <div class="offline-overlay"></div>
  </div>
</template>

<script>
export default {
  props: ["avatar", "color", "size", "online"],
  computed: {
    colorClass() {
      return this.color || "";
    },
    sizeClass() {
      return this.size || "default";
    },
    onlineClass() {
      if (this.online == undefined) {
        return "";
      }
      return this.online ? "online" : "offline";
    },
  },
};
</script>

<style lang="scss">
.avatar {
  border: 2px solid #eee;
  border-radius: 100%;
  width: 50px;
  height: 50px;
  padding: 4px;
  overflow: hidden;
  max-height: 100%;
  max-width: 100%;
  position: relative;

  &.sm {
    width: 35px;
    height: 35px;
  }

  img {
    overflow: hidden;
    max-height: 100%;
    max-width: 100%;
    position: absolute;
    top: 5px;
    left: 5px;
    right: 5px;
    bottom: 5px;
    height: auto !important;
    width: auto !important;
  }

  &.offline {
    .offline-overlay {
      display: block !important;
    }
  }

  .offline-overlay {
    display: none;
    position: absolute;
    background-color: rgba(255, 0, 0, 0.2);
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    border-radius: 100%;
    border: 4px solid red;
    z-index: 10;
  }
}
</style>
