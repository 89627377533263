<template>
  <v-card class="mr-2 ml-2 mb-5" max-width="400" min-width="300">
    <v-toolbar :color="team.color" :dark="$isDarkColor(team.color)">
      <v-toolbar-title>
        {{ team.name }}
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <v-chip class="points"> {{ pointsForTeam(team) }} P. </v-chip>
      <slot name="teamAction" v-bind:team="team"></slot>
    </v-toolbar>

    <v-list v-if="showPlayers">
      <v-list-item v-for="player in playersForTeam" :key="player.playerId">
        <v-list-item-avatar>
          <Avatar
            :avatar="player.avatar"
            v-bind:color="team.color"
            v-bind:online="player.online"
          />
        </v-list-item-avatar>

        <v-list-item-content>
          <v-list-item-title
            class="display-title"
            :class="{ 'red--text text--lighten-4': !player.online }"
            >{{ player.name }}</v-list-item-title
          >
        </v-list-item-content>

        <slot name="playerAction" v-bind:player="player"></slot>
      </v-list-item>
      <v-list-item v-if="playersForTeam.length == 0" class="text-center">
        Keine Spieler vorhanden...
      </v-list-item>
    </v-list>
  </v-card>
</template>
<script>
import Avatar from "../components/Avatar";

export default {
  name: "TeamList",
  props: ["quiz", "team", "showPlayers"],
  components: { Avatar },
  computed: {
    playersForTeam() {
      return this.quiz.players.filter(
        (player) => player.teamId == this.team.teamId
      );
    },
    pointsForTeam() {
      const sumUp = (x, y) => x + y;
      return (team) =>
        this.quiz.questions
          .filter((x) => x.winnerTeamId == team.teamId)
          .map((x) => x.points)
          .reduce(sumUp, 0);
    },
  },
};
</script>

<style scoped lang="scss">
.points {
  font-size: 1em !important;
  font-weight: 600;
}
</style>
